<template>
    <div class="temperature-container" :class="{ $mq }">
        <template v-if="isSensor">
            <div class="sensor-content">
                <!-- READING -->
                <template v-if="isSensor && readingSensor && sensorStatus == 'waiting'">
                    <LottieAnimation class="icon waiting" :timeToRepeat="1" :animationName="'sensor.json'" :loopAnimation="true"></LottieAnimation>
                    <div class="text" @click="sensorStatus = 'disconnected'" v-html="getString('popup.temperature.sensor.waiting')"></div>
                </template>

                <!-- NO DEVICE CONNECTED -->
                <template v-else-if="sensorStatus == 'disconnected'">
                    <!-- <div class="icon disconnected"></div> -->
                    <LottieAnimation class="icon waiting" :timeToRepeat="1" :animationName="'sensor-disconnect.json'" :loopAnimation="true"></LottieAnimation>
                    <div class="text" v-html="getString('popup.temperature.sensor.disconnected')"></div>
                </template>

                <template v-else-if="sensorStatus == 'connecting_2'">
                    <!-- <div class="icon connecting"></div> -->
                    <LottieAnimation v-if="searching" class="icon connecting" :timeToRepeat="1" :animationName="'sensor-bluetooth.json'" :loopAnimation="true"></LottieAnimation>

                    <div class="text" v-if="searching" v-html="getString('popup.temperature.sensor.connecting')"></div>
                </template>

                <!-- DEVICE CONNECTING -->
                <template v-else-if="sensorStatus == 'connecting'">
                    <!-- <div class="icon connecting"></div> -->
                    <LottieAnimation v-if="searching" class="icon connecting" :timeToRepeat="1" :animationName="'sensor-bluetooth.json'" :loopAnimation="true"></LottieAnimation>

                    <div class="text" v-if="searching" v-html="getString('popup.temperature.sensor.searching_device')"></div>
                    <div class="text" v-if="!searching && devices.length > 0" v-html="getString('popup.temperature.sensor.select_device')"></div>
                    <div class="step">
                        <ul class="devices list hide-scrollbar">
                            <template v-if="Object.keys(devices).length > 0">
                                <li v-for="dv in devices" :key="dv.id" class="item printer" :class="{ selected: BLEMAC == dv.id }" @click="selectDevice(dv)">
                                    {{ dv.name }}
                                </li>
                            </template>
                            <template v-else>
                                <li v-if="devices.length == 0" class="empty">
                                    {{ getString('config.printer.BLUETOOTH.empty') }}
                                </li>
                            </template>

                            <div v-if="!searching" class="btn-search" :class="{ filled: Object.keys(devices).length > 0 }" @click="searchDevices()">{{ getString('config.printer.refresh') }}</div>
                        </ul>
                    </div>
                </template>

                <!-- DEVICE CONNECTED (Same as waiting) -->
                <!-- <template v-else-if="sensorStatus == 'connected'">
                    <div class="icon connected" @click="readSensor()"></div>
                    <div class="text" @click="sensorStatus = 'error'" v-html="getString('popup.temperature.sensor.connected')"></div>
                </template> -->

                <!-- DEVICE ERROR -->
                <template v-else-if="sensorStatus == 'error'">
                    <!-- <div class="icon error"></div> -->
                    <LottieAnimation class="icon error" :timeToRepeat="3" :animationName="'sensor-error.json'" :loopAnimation="true"></LottieAnimation>

                    <!-- VOLVER A CONECTAR -->
                    <div class="text" v-html="getString(error_gps ? 'popup.temperature.sensor.error_gps' : 'popup.temperature.sensor.error')"></div>
                </template>

                <!-- MEASURE DEVICE FINISH -->
                <template v-else-if="sensorStatus == 'finish'">
                    <div class="value">{{ value }} {{ mesure }}</div>
                </template>
            </div>

            <div class="change-mode">
                <template v-if="isSensor && sensorStatus == 'finish'">
                    <div class="button" @click="erraseValue()">{{ getString('popup.temperature.sensor.delete_value') }}</div>
                    <div class="button" @click="readSensor()">{{ getString('popup.temperature.sensor.retake_sensor') }}</div>
                </template>
                <template v-else>
                    <div v-if="sensorStatus == 'disconnected' || sensorStatus == 'error'" class="button" @click="launchBLESensor()">{{ getString('popup.temperature.sensor.reconnect') }}</div>
                    <div v-if="this.sensorType != 'sensor'" class="button" @click="swapToManual()">{{ getString('popup.temperature.sensor.switch_manual') }}</div>
                    <div v-if="BLEMAC && sensorStatus == 'connecting'" class="button main" @click="connectDevice()">{{ getString('popup.temperature.sensor.connect') }}</div>
                </template>
            </div>
        </template>
        <template v-else>
            <!-- <div v-if="hasNegative" v-on:click="keyPolarity(false)" class="key positive" :class="classPositive"></div>
            <div v-if="hasNegative" v-on:click="keyPolarity(true)" class="key negative" :class="classNegative"></div> -->

            <!-- <input class="pin" disabled v-model="value" ref="refInputVal" /> -->
            <div class="numpad-error">
                <span v-if="isInvalidValue">{{ invalidValueMessage }}</span>
            </div>
            <div class="number-box">
                <div v-if="hasNegative" v-on:click="keyPolarity()" class="key negative"></div>
                <input class="pin" type="text" :disabled="isAPP" v-model="compValue" @input="validateInput" ref="refInputVal" />
                <!-- <div class="units">{{ mesure }}</div> -->
            </div>
            <div class="num-pad">
                <div v-on:click="keyPress(7)" class="key numberkey">7</div>
                <div v-on:click="keyPress(8)" class="key numberkey">8</div>
                <div v-on:click="keyPress(9)" class="key numberkey">9</div>
                <div v-on:click="keyPress(4)" class="key numberkey">4</div>
                <div v-on:click="keyPress(5)" class="key numberkey">5</div>
                <div v-on:click="keyPress(6)" class="key numberkey">6</div>
                <div v-on:click="keyPress(1)" class="key numberkey">1</div>
                <div v-on:click="keyPress(2)" class="key numberkey">2</div>
                <div v-on:click="keyPress(3)" class="key numberkey">3</div>
                <div v-on:click="keyBack" class="key keyback"></div>
                <div v-on:click="keyPress(0)" class="key numberkey">0</div>
                <div v-on:click="keyPressDecimal('.')" class="key decimal">.</div>
                <!-- <div v-on:click="sendError" class="key disabled"></div> -->
            </div>
            <div class="change-mode" v-if="sensorType != 'manual'">
                <div class="button" @click="readSensor()">{{ getString('popup.temperature.sensor.switch_sensor') }}</div>
            </div>
        </template>

        <!-- <span class="no-value" @click="saveAndClose">{{ getString('popup.default.no_value') }}</span> -->
    </div>
</template>

<script>
import Vue from 'vue'
import i18n from '@/i18n'

import LottieAnimation from '@/components/layout/LottieAnimation.vue'

export default {
    components: { LottieAnimation },
    props: {
        givenValue: { type: String, default: '0,0' },
        mesure: { type: String, default: 'ºC' },
        sensorType: { type: String, default: 'manual' },
        hasNegative: { type: Boolean, default: true }
    },
    data() {
        return {
            selNum: this.givenValue.split(',')[0],
            selDec: this.givenValue.split(',')[1],
            showPickerNum: false,
            showPickerDec: false,
            devices: [],
            pickerNumData: [
                {
                    currentIndex: 0,
                    list: [],
                    className: 'number'
                }
            ],
            pickerDecData: [
                {
                    currentIndex: 0,
                    list: [],
                    className: 'decimal'
                }
            ],
            value: this.givenValue,
            isNegative: this.checkNegative(),
            isSensor: this.sensorType == 'all' || this.sensorType == 'sensor' ? true : false,
            readingSensor: true,
            iTOScan: null,
            interval: null,
            BLEMAC: localStorage.sensor_selected ? localStorage.sensor_selected : false,
            searching: false,
            BLEServiceSubscribe: '00004590-81b7-4047-8773-00dba7a16acd',
            BLECharacteristicSubscribe: '00004593-81b7-4047-8773-00dba7a16acd',
            BLEService: '000035e0-8eb0-4405-a6aa-800f66d02188',
            BLECharacteristic: '000035e3-8eb0-4405-a6aa-800f66d02188',
            BLECharacteristicKEY: '000035e1-8eb0-4405-a6aa-800f66d02188', // WRITE KEY
            BLECharacteristicCMP: '000035e2-8eb0-4405-a6aa-800f66d02188', // WRITE COMPANY
            bluetoothService: '180A',
            sensorStatus: 'disconnected',
            ble_num_errors_subscribe: 0,
            ble_max_errors_subscribe: 3,
            ble_num_errors_connect: 0,
            ble_max_errors_connect: 3,
            ble_num_errors_read: 0,
            ble_max_errors_read: 3,
            ble_num_errors_write: 0,
            ble_max_errors_write: 3,
            error_gps: false,
            isInvalidValue: false,
            keys: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', null, '.', '+', '-']
        }
    },
    computed: {
        compValue: {
            get(){
                return this.value ? this.value + ' ' + this.mesure : ''
            },
            set(){}
        },
        invalidValueMessage() {
            return i18n.t('label.invalid_value')
        },
        isAPP(){
            return process.env.VUE_APP_IS_APP == 'TRUE'
        }
    },
    methods: {
        load() {
            if (!(this.sensorType == 'all' || this.sensorType == 'sensor')) {
                // SI VALOR YA DEFINIDO MOSTRAR PANTALLA CON EL VALOR
                // SIN VALOR DEFINIDO, MOSTRAR PANTALLA DE CONECTING + llamar a la funcion readSensor
                this.updateSensorStep(4)
            } else {
                if (this.value) {
                    this.sensorStatus = 'finish'
                    this.updateSensorStep(2)
                } else {
                    this.sensorStatus = 'connecting'
                    this.readSensor()
                    this.updateSensorStep(3)
                }
            }
        },
        // SENSOR METHODS
        readDataFromSubscribe(data) {
            console.log('BLE READ FROM SUBSCRIBE:', data)
            this.ble_num_errors_subscribe = 0

            if (data) {
                var msg = bytesToString(data)
                this.sensorStatus = 'finish'

                //END BLE SENSOR LOGIC
                if (this.isSensor) {
                    this.value = msg.split(' ')[0]
                    this.readingSensor = false
                    this.updateSensorStep(2)
                    this.sendValue()
                }
            }
        },
        unsubscribeBLE(callback) {
            var self = this

            if (this.BLEMAC) {
                ble.stopNotification(
                    self.BLEMAC,
                    self.BLEServiceSubscribe,
                    self.BLECharacteristicSubscribe,
                    function () {
                        console.log('Device unsubscribed...')
                        if (typeof callback === 'function') {
                            callback()
                        }
                    },
                    function (err) {
                        console.error('ERROR UNSUBSCRIBE', err)
                        self.BLEMAC = false
                        localStorage.removeItem('sensor_selected')
                        if (err) {
                            if (typeof callback === 'function') {
                                callback()
                            }
                        } else if (self.ble_num_errors_subscribe < self.ble_max_errors_subscribe) {
                            self.ble_num_errors_subscribe++
                            // self.unsubscribeBLE()
                        } else {
                            self.sensorStatus = 'error'
                        }
                    }
                )
            } else if (typeof callback === 'function') {
                callback()
            }
        },
        subscribeBLE() {
            var self = this
            this.ble_num_errors_read = 0
            this.sensorStatus = 'waiting'
            ble.startNotification(self.BLEMAC, self.BLEServiceSubscribe, self.BLECharacteristicSubscribe, self.readDataFromSubscribe, function (err) {
                console.error('ERROR SUBSCRIBE', err)
                if (err.error == 'isDisconnected') {
                } else if (self.ble_num_errors_subscribe < self.ble_max_errors_subscribe) {
                    self.ble_num_errors_subscribe++
                    self.signBLE()
                } else {
                    self.sensorStatus = 'error'
                }
            })
        },
        signBLE() {
            var self = this
            self.ble_num_errors_connect = 0

            ble.read(
                self.BLEMAC,
                self.BLEService,
                self.BLECharacteristic,
                function (data) {
                    var msg = bytesToString(data)
                    console.error('BLE READ', msg)
                    if (data) {
                        if (msg == 'KEY_VALID') {
                            self.sensorStatus = 'connecting_2'
                            self.subscribeBLE()
                        } else if (msg == 'KEY_INVALID' || msg == 'WAIT_KEY') {
                            // WRITE KEY
                            let utf8Encode = hexa_to_decimal('D6E6FCC15DBAB74F502F4836C66449E1')
                            console.log(utf8Encode.buffer)
                            ble.write(
                                self.BLEMAC,
                                self.BLEService,
                                self.BLECharacteristicKEY,
                                utf8Encode.buffer,
                                function (data) {
                                    console.log('KEY sended')
                                    self.signBLE()
                                },
                                function (err) {
                                    console.error('ERROR WRITE', err)
                                    if (self.ble_num_errors_read < self.ble_max_errors_read) {
                                        self.ble_num_errors_read++
                                        self.signBLE()
                                    } else {
                                        self.sensorStatus = 'error'
                                    }
                                }
                            )
                        } else if (msg == 'COMPANY_INVALID' || msg == 'WAIT_COMPANY') {
                            //WRITE COMPANY
                            let utf8Encode = ascii_to_decimal('Grupo Alsea')
                            console.log(utf8Encode.buffer)
                            ble.write(
                                self.BLEMAC,
                                self.BLEService,
                                self.BLECharacteristicCMP,
                                utf8Encode.buffer,
                                function (data) {
                                    console.log('Company sended')
                                    self.signBLE()
                                },
                                function (err) {
                                    console.error('ERROR WRITE', err)
                                    if (self.ble_num_errors_read < self.ble_max_errors_read) {
                                        self.ble_num_errors_read++
                                        self.signBLE()
                                    } else {
                                        self.sensorStatus = 'error'
                                    }
                                }
                            )
                        }
                    } else {
                        if (self.ble_num_errors_read < self.ble_max_errors_read) {
                            self.ble_num_errors_read++
                            self.signBLE()
                        } else {
                            self.sensorStatus = 'error'
                        }
                    }
                },
                function (err) {
                    console.error('ERROR READ', err)
                    if (self.ble_num_errors_read < self.ble_max_errors_read) {
                        self.ble_num_errors_read++
                        self.signBLE()
                    } else {
                        self.sensorStatus = 'error'
                    }
                },
                {
                    address: self.BLEMAC,
                    service: self.BLEService,
                    characteristic: self.BLECharacteristic
                }
            )
        },
        disconnectBLE(callback) {
            var self = this
            if (this.BLEMAC) {
                ble.disconnect(
                    self.BLEMAC,
                    function (data) {
                        self.BLEMAC = false
                        localStorage.removeItem('sensor_selected')
                        console.log('DisconnectBLE - disconnected')
                        if (typeof callback === 'function') {
                            callback()
                        }
                    },
                    function (err) {
                        console.error('ERROR DISCONNECT', err)
                        if (self.ble_num_errors_connect < self.ble_max_errors_connect) {
                            self.ble_num_errors_connect++
                            self.disconnectBLE(callback)
                        } else {
                            self.sensorStatus = 'error'
                            this.BLEMAC = false
                            localStorage.removeItem('sensor_selected')
                            if (typeof callback === 'function') {
                                callback()
                            }
                        }
                    }
                )
            } else if (typeof callback === 'function') {
                callback()
            }
        },
        connectBLE() {
            var self = this
            ble.connect(
                this.BLEMAC,
                function (data) {
                    console.log('BLE Connect:', data)
                    self.sensorStatus = 'connecting_2'
                    self.signBLE()
                },
                function (err) {
                    console.log('ERROR CONNECT', err)
                    if (self.ble_num_errors_connect < self.ble_max_errors_connect) {
                        self.ble_num_errors_connect++
                        self.connectBLE()
                    } else {
                        self.sensorStatus = 'error'
                        this.BLEMAC = false
                        localStorage.removeItem('sensor_selected')
                    }
                }
            )
        },
        connectDevice() {
            localStorage.setItem('sensor_selected', this.BLEMAC)
            this.sensorStatus = 'connecting_2'
            this.connectBLE()
        },
        selectDevice(sensor) {
            log('Connecting to ', sensor)
            this.BLEMAC = sensor.id
        },
        detectDeviceBLE(data) {
            console.log('Device detected', data)
            var self = this
            if (data) {
                let devices = [...this.devices]
                devices.push(data)
                this.devices = [...devices]
            }
        },
        stopScanBLE(callback) {
            console.log('stopScanBLE - init')
            clearTimeout(this.iTOScan)
            ble.stopScan(
                function () {
                    console.log('Stop scanning...')
                    if (typeof callback === 'function') {
                        console.log('Launching callback stop')
                        callback()
                    }
                },
                function (err) {
                    console.log('Error stop scanning', err)
                    if (typeof callback === 'function') {
                        callback()
                    }
                }
            )
        },
        scanBLE() {
            var self = this
            console.log('Start scanning...')
            const timeLimitScan = 10
            this.searching = true
            this.devices = []

            if (this.BLEMAC) {
                this.connectBLE()
            } else {
                ble.scan([this.bluetoothService], 10, self.detectDeviceBLE, function (err) {
                    console.log('ERROR Scanning...', err)
                    self.stopScanBLE()
                    self.sensorStatus = 'error'
                })
                // let i = 1
                // this.interval = setInterval(() => {
                //     self.detectDeviceBLE({
                //         name: 'TI SensorTag ' + i,
                //         id: 'BD922605-1B07-4D55-8D09-B66653E51BBA' + i
                //     })
                //     i++
                // }, 3000)

                this.iTOScan = setTimeout(
                    function (self2) {
                        console.log('Time limit scan...')
                        self2.searching = false
                        self2.stopScanBLE()
                        if (self2.devices.length == 0) {
                            self2.sensorStatus = 'error'
                        }
                        clearInterval(self2.interval)
                    },
                    timeLimitScan * 1000,
                    this
                )
            }
        },
        launchBLESensor(bTryToConnect) {
            console.log('LaunchBLESensor...')
            var self = this
            bTryToConnect = typeof bTryToConnect === 'undefined' ? true : bTryToConnect
            ble.isEnabled(
                function () {
                    ble.isLocationEnabled(
                        function () {
                            self.sensorStatus = 'connecting'
                            self.ble_num_errors_subscribe = 0
                            self.ble_num_errors_connect = 0
                            self.ble_num_errors_read = 0
                            self.ble_num_errors_write = 0
                            self.error_gps = false

                            if (self.BLEMAC) {
                                self.stopScanBLE(() => {
                                    self.unsubscribeBLE(() => {
                                        self.disconnectBLE(self.scanBLE)
                                    })
                                })
                            } else {
                                self.stopScanBLE(self.scanBLE)
                            }
                        },
                        function () {
                            console.log('location services are *not* enabled')
                            self.error_gps = true
                            self.sensorStatus = 'error'
                        }
                    )
                },
                function () {
                    if (bTryToConnect) {
                        ble.enable(
                            function () {
                                console.log('Bluetooth is enabled')
                                self.launchBLESensor(false)
                            },
                            function () {
                                self.launchBLESensor(false)
                            }
                        )
                    } else {
                        console.log('Bluetooth is *not* enabled')
                        self.sensorStatus = 'error'
                    }
                }
            )
        },

        searchDevices() {
            var self = this
            self.stopScanBLE(self.scanBLE)
        },

        async readSensor() {
            this.updateSensorStep(1)
            this.isSensor = true
            this.readingSensor = true

            //START BLE SENSOR LOGIC
            this.launchBLESensor()
        },

        erraseValue() {
            this.value = ''
            this.sendValue()
            this.$emit('saveAndClose')
        },

        updateSensorStep(step) {
            this.$emit('updateSensorStep', step)
        },

        swapToManual() {
            var self = this
            this.stopScanBLE(() => {
                self.unsubscribeBLE(
                    self.disconnectBLE(function () {
                        self.updateSensorStep(2)
                        self.isSensor = false
                    })
                )
            })
        },

        // NUMPAD METHODS
        keyPress(val) {
            if ((!this.value.includes('.') && (this.value.match(/\d/g) || []).length > 2) || (this.value.includes('.') && this.value.split('.')[1].length > 1)) {
            } else if (!this.isDisabled) {
                if ((this.value == '0' && this.pinType != 'password') || (this.value == this.startNumber && this.startNumber != -1 && !this.modified) || (this.givenValue == '' && this.startNumber != -1)) {
                    this.value = this.value + val
                } else {
                    this.value = this.value + val
                }

                if (!this.modified) this.modified = true
                this.value = parseFloat(this.value)
                this.value = String(this.value)
                // this.sendValue()
            }

            this.checkNegative()
            this.sendValue()
        },

        keyBack() {
            if (!this.isDisabled) {
                this.value = String(this.value).slice(0, -1)
                this.sendValue()
                this.checkNegative()
            }
        },

        keyPressDecimal(val) {
            if (!this.isDisabled) {
                if (this.value.indexOf(val) == -1) {
                    this.value = this.value + val
                }
                this.sendValue()
            }
        },

        keyPolarity(polarity = null) {
            if (!this.isDisabled) {
                // Check the polarity parameter
                if (polarity === true) {
                    this.isNegative = false;
                } else if (polarity === false) {
                    this.isNegative = true;
                } else {
                    this.isNegative = !this.isNegative
                }

                if (this.isNegative) {
                    if (!this.value.startsWith('-')) {
                        this.value = '-' + this.value
                    }
                } else {
                    this.value = this.value.replace('-', '')
                }
            }

            if (this.value && this.value !== '-') {
                this.value = parseFloat(this.value)
                this.value = String(this.value)
            }
            this.sendValue()
        },

        checkNegative: function () {
            if (this.value) {
                this.isNegative = String(this.value).indexOf('-') != -1
                return this.isNegative
            } else {
                this.isNegative = false
                return this.isNegative
            }
        },

        sendError(e) {
            this.$emit('sendError', 'error :(')
        },

        saveAndClose() {
            this.$emit('sendValue', '-')
            this.$emit('saveAndClose')
        },
        // setValue() {
        //     this.$emit('sendValue', this.selNum + ',' + this.selDec)
        // },

        sendValue() {
            this.$refs.refInputVal.value = this.compValue
            this.isInvalidValue = false
            if (!isNaN(this.value)) {
                if (this.isNegative) {
                    // this.$emit('sendValue', '-' + this.value)
                    this.$emit('sendValue', this.value)
                } else {
                    this.$emit('sendValue', this.value)
                }
            }
            this.checkNegative()
        },

        changeNum(gIndex, iIndex) {
            this.selNum = this.pickerNum[gIndex].list[iIndex]
            this.setValue()
        },
        changeDec(gIndex, iIndex) {
            this.selDec = this.pickerDec[gIndex].list[iIndex]
            this.setValue()
        },

        getString(str) {
            return i18n.t(str)
        },
        getStringPlural(str, pos) {
            return i18n.tc(str, pos)
        },
        classNegative() {
            if (this.isNegative) return 'selected'
        },
        focusInput(){
            setTimeout(()=>{
                let input = this.$refs.refInputVal
                input.focus()
                input.selectionStart = input.selectionEnd = input.value.length
            }, 10)
        },
        validateInput(val) {
            if (!this.keys.includes(val.data)) {
                if(this.compValue == ''){
                    this.sendValue();
                    this.isInvalidValue = true;
                    return;
                }
                this.sendValue();
                this.isInvalidValue = true;
                return;
            }

            switch (val.data) {
                case null:
                    this.keyBack()
                    break
                case '.':
                    this.keyPressDecimal('.')
                    break
                case '+':
                    this.keyPolarity(true)
                    break
                case '-':
                    this.keyPolarity(false)
                    break
                default:
                    this.keyPress(val.data)
                    break
            }
        }
    },
    created() {
        this.load()
    },
    mounted() {
        this.checkNegative()
        // setTimeout(() => {
        //     this.showPickerNum = true
        //     this.showPickerDec = true
        // }, 100)
        this.$nextTick(() => {
            this.focusInput();
        });
        window.addEventListener('mousedown', this.focusInput)
    },
    beforeDestroy() {
        window.removeEventListener('mousedown', this.focusInput)
        var self = this
        if ((this.sensorType == 'all' || this.sensorType == 'sensor') && ble) {
            this.stopScanBLE(() => {
                self.unsubscribeBLE(self.disconnectBLE)
            })
        }
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.popup-container {
    .popup.temperature {
        .temperature-container {
            @include flex-direction(column);
            position: relative;
            $key-size: rem(60px);
            $topkey-size: rem(60px);
            width: 100%;

            .sensor-content {
                @include display-flex();
                @include align-content();
                @include align-items();
                @include justify-content();
                @include flex-direction(column);
                #app.icon {
                    margin: 25px auto;
                    height: 150px;
                    width: 150px;
                }

                .icon {
                    margin: 25px auto;
                    height: 150px;
                    width: 150px;

                    &.disconnected {
                        @include background($image: img('circle_exclamation_accent.svg'), $size: 120px);
                    }

                    &.connecting {
                        @include background($image: img('loader_primary.svg'), $size: 120px);
                    }

                    &.connected {
                        @include background($image: img('checked_ok.svg'), $size: 120px);
                    }

                    &.error {
                        @include background($image: img('error_alert.svg'), $size: 120px);
                    }
                }

                .text {
                    @include font-size(ml);
                    font-family: $text;
                    color: $neutro-s80;
                    text-align: center;
                    margin-bottom: 50px;
                }

                .value {
                    @include font-size(46px);
                    margin: 50px auto;
                    color: $neutro-s80;
                    font-family: $text-bold;
                }
            }

            .step {
                @include display-flex();
                @include justify-content();
                @include flex-wrap(wrap);
                min-height: 60px;

                .devices {
                    width: 100%;
                    max-width: 350px;
                    margin: 0 auto;
                    margin-top: 25px;
                    max-height: 600px;
                    overflow: auto;

                    .meta {
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        margin-bottom: 10px;
                        width: 100%;
                        text-align: center;

                        .label {
                            @include display-flex();
                            @include justify-content();
                            @include align-items();
                            height: 30px;
                        }
                    }
                    .btn-search {
                        @include border-radius(3px);
                        @include display-flex();
                        @include justify-content();
                        @include align-items();
                        @include font-size(m);
                        @include background($image: img('reload_main_s30.svg'), $size: 20px, $position: left 10px center);
                        background-color: $neutro;
                        height: 40px;
                        cursor: pointer;
                        padding: 6px 9px;
                        padding-left: 40px;
                        margin: 15px auto 0;
                        width: fit-content;
                        font-family: $text;
                        color: $main-s50;

                        &.filled {
                            background-image: img('reload_neutro_s70.svg');
                            background-color: transparent;
                            color: $neutro-s70;
                        }
                    }

                    .item {
                        @include background($image: img('unchecked_filled.svg'), $size: 20px, $position: left 10px center);
                        @include border-radius(6px);
                        @include interaction();
                        @include font-size(m);
                        padding: 10px 15px;
                        color: $neutro-s70;
                        width: 100%;
                        margin: 10px auto;
                        font-family: $text-bold;
                        background-color: #ece1e0;
                        padding-left: 40px;

                        &.selected {
                            @include background($image: img('checked_main.svg'), $size: 20px, $position: left 10px center);

                            .meta {
                                font-family: $text-bold;
                                color: $main;
                            }
                        }
                    }
                }
            }

            &.portrait {
                $key-size: rem(50px);
            }

            .number-box {
                @include display-flex();
                @include flex-wrap($w: wrap);
                @include align-items();
                @include justify-content();
                position: relative;
                margin: 15px 0;

                .hint {
                    @include font-size(s);
                    margin-bottom: 5px;
                    width: 100%;
                    text-align: center;
                    font-family: $text-light;
                    color: $inactive-s20;

                    b {
                        color: $inactive-s20;
                    }
                }

                .negative {
                    margin-left: 0;

                    &.selected {
                        background-color: $main;
                        color: #fff;
                    }
                }

                .pin {
                    @include border-radius(4px);
                    @include font-size(xl);
                    border: 2px solid #eee;
                    height: 40px;
                    width: calc((#{$key-size} * 3));
                    background-color: #f9f9f9 !important;
                    color: #666 !important;
                    text-align: center;
                    padding: 24px 0;
                    &:disabled {
                        background-color: #f9f9f9 !important;
                        color: #666 !important;
                        opacity: 1;
                    }
                }

                .units {
                    @include display-flex();
                    @include align-items();
                    @include justify-content();
                    @include border-radius(4px);
                    @include font-size(l);
                    font-family: $text;
                    color: $main-s20;
                    width: $key-size;
                    height: $key-size;
                    margin: rem(6px);
                }
            }

            .num-pad {
                @include display-flex();
                @include flex-wrap($w: wrap);
                @include align-items();
                @include justify-content();
                width: calc((#{$key-size} * 3) + (#{rem(12px)} * 3));
                margin: 0 auto;

                .negative {
                    // position: absolute;
                    // @include border-radius(100px);
                    left: 0;
                    bottom: 0;
                    height: $key-size;
                    width: $key-size;
                    background-color: $inactive-t80;

                    &.selected {
                        background-color: $main;
                        color: #fff;
                    }
                }

                .pin {
                    @include border-radius(4px);
                    @include font-size(xl);
                    border: 2px solid #eee;
                    height: $key-size;
                    width: calc((#{$key-size} * 2) + (#{rem(12px)} * 2) - #{rem(3px)});
                    background-color: #f9f9f9;
                    color: #666;
                    text-align: center;
                    padding: 24px 0;
                }
            }

            .key {
                @include interaction();
                @include display-flex();
                @include align-items();
                @include justify-content();
                @include border-radius(4px);
                @include font-size(l);
                font-family: $text-bold;
                color: $main-s20;
                width: $key-size;
                height: $key-size;
                margin: rem(6px);
                background-color: $inactive-t80;

                // @include bgHover($main-t90);
                &:active {
                    cursor: pointer;
                    animation: numpadClick 0.2s;
                    @include bgHover($main-t80);
                }

                @keyframes numpadClick {
                    0%,
                    100% {
                        transform: scale(1, 1);
                    }
                    25% {
                        transform: scale(1.1, 1.1);
                    }
                    50% {
                        transform: scale(1, 1);
                    }
                    75% {
                        transform: scale(1.05, 1.05);
                    }
                }

                &.keyback {
                    @include background($image: img('undo_primary.svg'), $size: 25px);
                    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='iso-8859-1'%3F%3E%3C!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' version='1.1' id='Layer_1' x='0px' y='0px' viewBox='0 0 472.615 472.615' style='enable-background:new 0 0 472.615 472.615;' xml:space='preserve' width='512px' height='512px'%3E%3Cg%3E%3Cg%3E%3Cpath d='M139.569,96.738L0,236.307l139.569,139.57h333.046v-139.57V96.738H139.569z M362.673,305.637l-13.923,13.922 l-69.329-69.332l-69.33,69.332l-13.922-13.922l69.329-69.332l-69.329-69.332l13.922-13.923l69.33,69.332l69.329-69.332 l13.923,13.923l-69.33,69.332L362.673,305.637z' fill='%23475b96'/%3E%3C/g%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3Cg%3E%3C/g%3E%3C/svg%3E%0A");
                }
                &.disabled {
                    @include opacity(0);
                }
                &.negative {
                    @include background($image: img('add_less.svg'), $size: 25px);
                }
            }

            .no-value {
                @include interaction();
                @include border-radius(3px);
                @include font-size(m);
                @include display-flex();
                @include align-items();
                @include justify-content();
                font-family: $text;
                color: $main-dark;
                height: rem(40px);
                // display: block;
                width: rem(165px);
                margin: rem(6px) auto;
                text-align: left;
                margin-top: 20px;
            }

            .numpad-error {
                text-align: center;
                min-height: 1.5em !important;
                span{
                    color: $color-error-500;
                }
            }

            @include small {
                $m-key-size: rem(65px);
                $m-topkey-size: rem(65px);

                .num-pad {
                    width: calc((#{$m-key-size} * 3) + (#{rem(12px)} * 3));
                }
                .key {
                    // width: $m-key-size;
                    // height: $m-key-size;

                    &.operator {
                        width: $m-topkey-size;
                        height: $m-topkey-size;
                    }
                }
            }
            &.desktop {
                .key {
                    @include bgHover($main-t90);
                }
            }

            .change-mode {
                @include display-flex();
                @include align-items();
                @include flex-direction(row);
                @include flex-wrap(wrap);
                @include justify-content(space-between);
                width: auto;
                max-width: 100%;
                height: auto;
                text-align: right;
                margin-top: 20px;

                .button {
                    @include display-flex();
                    @include align-items();
                    @include justify-content();
                    @include border-radius(4px);
                    @include font-size(m);
                    border: 1px solid rgba(131, 131, 131, 0.03);
                    height: auto;
                    margin: 6px auto;
                    padding: 0 20px;
                    color: $main;
                    font-family: $text-light;
                    background-color: $main-t90;
                    // float: right;
                    width: 49%;
                    min-height: 50px;
                    overflow: hidden;
                    cursor: pointer;
                    text-align: center;

                    &.main {
                        background-color: $main;
                        color: #fff;
                        font-family: $text-bold;
                    }
                }
            }
        }

        @media all and (orientation: portrait) {
            .numpad-content {
                width: 100%;
                height: auto;

                .num-pad {
                    width: calc(270px + 7.5px * 6);
                    height: auto;
                    overflow-x: hidden;

                    .key {
                        width: 90px;
                        height: 90px;
                    }
                }
            }
        }
        .pop-footer {
            // min-height: unset;
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss">
.popup-container.portrait {
    .popup.temperature {
        .pop-body {
            .temperature-container {
                @include display-flex();
                @include align-items();
                @include justify-content();
                @include flex-direction(column);
                // @include flex-wrap(wrap);
                max-width: calc(100%);
                overflow: auto;
                height: 100%;
                position: relative;
                width: 100%;

                .sensor-content {
                    .sensor {
                        @include background($size: 90px);
                        height: 90px;
                        width: 90px;
                        margin: 20px auto;
                    }
                    .text {
                        @include font-size(s);
                        margin: 20px auto;
                    }

                    .value {
                    }
                }

                .number-box {
                    margin: 0;

                    .hint {
                        margin-bottom: -5px;
                    }

                    .negative {
                    }

                    .pin {
                        height: 62px;
                    }

                    .units {
                    }
                }

                .change-mode {
                    .button {
                        @include font-size(s);
                        width: 100vw;
                    }
                }
            }
        }

        .pop-footer {
            @include align-content();
            height: auto;
            min-height: unset;
            .buttons {
                margin-top: 0px;
            }
        }
    }
}
</style>
